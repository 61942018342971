import './WhoWeAre.scss';
// import React from 'react'

// import Container from 'components/layout/Container'
// import Section from 'components/layout/Section'

export default function AboutWhoWeAre() {
    return null;
    // return (
    //     <Container>
    //         <Section>
    //             <h3 className="we-are-header">We work with a range of companies from government agencies to international organisations and leading automotive brands.</h3>
    //         </Section>
    //     </Container>
    // )
}
